.checkboxRow {
}

.hr {
    color: black;
    background-color: black;
    height: 5;
}

.subcategoryBox {
    background-color: rgb(197, 197, 197);
}